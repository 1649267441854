import { Box, SvgIcon, Typography } from '@mui/material';
import { FunctionComponent } from 'react';
import ModerneWordmark from '../../../public/moderne-wordmark.svg';

export const SignInHeader: FunctionComponent = () => {
  return (
    <Box
      sx={{
        maxWidth: 300
      }}>
      <Box
        sx={{
          marginBottom: { xs: 3, sm: 4 },
          display: 'flex',
          justifyContent: { xs: 'center', sm: 'left' }
        }}>
        <SvgIcon
          viewBox="0 0 152 23"
          sx={{
            fill: 'white',
            height: 46 * 0.66,
            width: 304 * 0.66
          }}
          component={ModerneWordmark}
        />
      </Box>
      <Box
        sx={{
          marginBottom: 3,
          width: '100%'
        }}>
        <Typography
          variant="body1"
          align="left"
          sx={{
            fontWeight: 400,
            color: 'white',
            textAlign: { xs: 'center', sm: 'left' }
          }}>
          Moderne automates code updates, migrations, security, and analysis at
          scale so developers can spend their time building new things instead
          of maintaining the old.
        </Typography>
      </Box>
    </Box>
  );
};
