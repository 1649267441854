import { useEffect, useState } from 'react';

const SYSTEM_THEME_DARK_STATE = 'dark' as const;
const SYSTEM_THEME_LIGHT_STATE = 'light' as const;

type SystemThemeState =
  | typeof SYSTEM_THEME_DARK_STATE
  | typeof SYSTEM_THEME_LIGHT_STATE;

type SystemThemeHook = {
  systemTheme: SystemThemeState;
  isSystemThemeDark: boolean;
  isSystemThemeLight: boolean;
};
/**
 * Returns the state of the system theme
 */
export const useSystemTheme = (): SystemThemeHook => {
  const [systemTheme, setSystemTheme] = useState(
    window.matchMedia &&
      window.matchMedia('(prefers-color-scheme: dark)').matches
      ? SYSTEM_THEME_DARK_STATE
      : SYSTEM_THEME_LIGHT_STATE
  );

  useEffect(() => {
    const handleChange = (e) => {
      setSystemTheme(
        e.matches ? SYSTEM_THEME_DARK_STATE : SYSTEM_THEME_LIGHT_STATE
      );
    };

    // Set up the event listener for dark mode
    const darkModeMediaQuery = window.matchMedia(
      '(prefers-color-scheme: dark)'
    );
    darkModeMediaQuery.addEventListener('change', handleChange);

    // Clean up the event listener when the component unmounts
    return () => darkModeMediaQuery.removeEventListener('change', handleChange);
  }, []);

  return {
    systemTheme,
    isSystemThemeDark: systemTheme === SYSTEM_THEME_DARK_STATE,
    isSystemThemeLight: systemTheme === SYSTEM_THEME_LIGHT_STATE
  };
};
